<template>
  <section class="last-changes block">
    <div class="last-changes-header block-wrapper header">
      <h2>Last changes</h2>
      <button @click="toggleNew" class="btn" :class="{transparent: hide_new}">{{
          hide_new ? 'Show new' : 'Hide new'
        }}
      </button>
    </div>
    <div class="table-container">
      <Preloader v-if="pending"/>
      <table v-if="list.length > 0" class="last-changes-table">
        <tr v-for="(item, idx) in list" :key="`change-${idx}`">
          <td>{{ idx + 1 }}</td>
          <td>
            <router-link :to="`/dashboard/articles?user=${item.user_id}`">
              <span class="last-changes-name">{{ item.editor_email }}</span>
            </router-link>
          </td>
          <td>
            <div class="last-changes-country">
              <span>[{{ item.session_info.country }}]</span>
              <img
                v-if="item.session_info.flag_icon"
                :alt="item.session_info.country"
                :src="item.session_info.flag_icon"
              >
            </div>
          </td>
          <td>
            <p>{{ item.session_info.ip }}</p>
          </td>
          <td>

            <div class="last-changes-title">
              <span class="lang">{{ item.language }}</span>
              <router-link :to="`/dashboard/articles/${item.text_id}`">
                <span>{{ item.title }}</span>
              </router-link>
            </div>
          </td>
          <td>
            <p>{{ $moment(item.created_at).format('YYYY-MM-DD') }}</p>
            <p>{{ $moment(item.created_at).format('HH:MM:SS') }}</p>
          </td>
          <td><span class="last-changes-next">{{ item.description }}</span></td>
          <td><span class="last-changes-ready c-green">{{
              item.diff !== null ? `${Math.trunc(item.diff)}%` : '--'
            }}</span></td>
          <td>
            <router-link :to="`/dashboard/articles/${item.text_id}`" class="btn transparent">Open</router-link>
          </td>
        </tr>
      </table>
      <div v-else class="table-empty">
        <p v-if="!pending">Empty</p>
      </div>
      <APagination ref="pagination" :visible="list.length > 0" :page="page" :count="paginationPages"
                   :callback="setPagination"/>
    </div>
  </section>
</template>

<script>
import Preloader from "../../../components/common/Preloader";
import {mapActions} from "vuex";

export default {
  name: "LastChanges",
  components: {
    APagination: () => import('@/components/common/APagination'),
    Preloader,
  },
  data() {
    return {
      list: [],
      page: 1,
      quantity: 30,
      total: 0,
      pending: false,
      hide_new: false
    }
  },
  computed: {
    paginationPages() {
      let pageNum = Math.trunc(this.total / this.quantity);
      if ((this.total % this.quantity) > 0) {
        pageNum++;
      }
      return pageNum;
    },
  },
  methods: {
    ...mapActions([
      'getLastChanges'
    ]),
    async toggleNew() {
      this.hide_new = !this.hide_new;
      await this.loadData();
    },
    async setPagination(num) {
      this.page = num;
      await this.loadData();
    },
    async loadData() {
      this.pending = true;
      await this.getLastChanges({
        per_page: this.quantity,
        current_page: this.page,
        hide_new: this.hide_new
      }).then(({data, pagination}) => {
        this.pending = false;
        this.total = pagination.total;
        this.list = data;

        if (data.length === 0 && this.page > 1) {
          this.$refs['pagination'].onChangePage(1);
        }
      }).catch(error => {
        this.pending = false;
        this.$notify({
          title: 'Last changes',
          text: error.response && error.response.data.message,
          type: 'error'
        });
      });
    },
  }
}
</script>

<style scoped>

</style>
